<template>
  <NuxtPage />
</template>

<script lang="ts" setup>
// Global lang, link, and meta
useHead({
  htmlAttrs: {
    lang: 'en',
  },
  link: [
    {
      href: '/favicon.ico',
      rel: 'icon',
      sizes: 'any',
    },
    {
      href: '/favicon.svg',
      rel: 'icon',
      type: 'image/svg+xml',
    },
    {
      href: '/img/apple-touch-icon.png',
      rel: 'apple-touch-icon',
    },
    {
      href: 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200',
      rel: 'stylesheet',
      as: 'font',
    },
  ],
  meta: [
    {
      content: 'width=device-width, initial-scale=1',
      name: 'viewport',
    },
  ],
  script: [
    {
      hid: 'gtmHead',
      innerHTML: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-58GDDQF');`,
    },
  ],
});
</script>
